//
//
//
//
//
//

import wx from 'weixin-js-sdk';
import axios from 'axios';
export default {
	data: ()=> ({
		id: null
	}),
	mounted: function() {
		this.initWxConfig()
		this.id = this.$route.params.id || null
	},
	methods: {
		initWxConfig: function() {
			let url = window.location.href
			url = url.split('#')[0]
			//     wx60ced1c8de7908d5
			const promise = axios.get('/api-pass/wechat/h5/sign/share' + '?app_id=wx60ced1c8de7908d5&url=' + url);
			promise.then(res => {
				if (res.data.code == 200) {
					const sign = res.data.data;
					this._wxConfigJSSDK(sign);
				}
			});
			promise.catch((err) => {
				console.log(err.response);
			})
		},
		_wxConfigJSSDK: function(sign){
			console.log(sign)
			console.log(parseInt(new Date().getTime() / 1000), sign.timestamp, new Date().getTime() / 1000 - sign)
			wx.config({
				debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: sign.appId + '', // 必填，公众号的唯一标识
				timestamp: parseInt(sign.timestamp), // 必填，生成签名的时间戳
				nonceStr: sign.nonceStr + '', // 必填，生成签名的随机串
				signature: sign.signature + '',// 必填，签名
				jsApiList: ['checkJsApi', 'chooseImage', 'downloadImage', 'previewImage', 'uploadImage'] // 必填，需要使用的JS接口列表
			})
			wx.ready(function(){
                wx.checkJsApi({
                    jsApiList: [
                        'chooseImage',
                        'previewImage',
                        'uploadImage',
                        'downloadImage'
                    ],
                    success: function (res) {
                        if (res.checkResult.getLocation == false) {
                            console.error('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
                            return;
                        }else{
							console.log('授权成功', res)
                        }
					},
					fail: err=> {
						console.log(err)
					}
                });
			});

			wx.error(function(res){
				console.error(res)
			});
		},
		shooseImage: function() {
			wx.chooseImage({
				count: 1, // 默认9
				sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
				sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
				success: function (res) {
					var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
					let targetImgId = localIds[0]
					
					if (window.__wxjs_is_wkwebview) {
							wx.getLocalImgData({
								localId: targetImgId,
								success: (res) => {
									let localData = res.localData // localData是图片的base64数据，可以用img标签显示
									localData = localData.replace('jgp', 'jpeg') // iOS 系统里面得到的数据，类型为 image/jgp,因此需要替换一下
									console.log(localData)
								}
							})
					}
				}
			});
		}
	}
}
